import { InputHidden } from "@heart/components";
import I18n from "i18n-js";
import { isEqual, size, omit, has, includes, some, pick } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useRef, useEffect, useState } from "react";

import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

const getUpdatedOptions = (previousRaces, newRaces, exclusiveKeys) => {
  const getExclusiveOptions = candidates => pick(candidates, exclusiveKeys);

  if (
    Object.keys(newRaces).length <= 1 ||
    !some(Object.keys(newRaces), key => includes(exclusiveKeys, key))
  ) {
    return false;
  }

  const previousExclusives = getExclusiveOptions(previousRaces);
  const newExclusives = getExclusiveOptions(newRaces);

  // An unlikely invalid state is that there is more than one exclusive option already
  // selected. This would happen if we change exlusive options and the historical data
  // is out of sync with current requirements. If you do not edit the invalid state will
  // be preserved by the isFirstRun check. If you do edit from that point we'll just
  // choose an arbitrary exlusive option to reach a valid state.
  if (size(previousExclusives) > 1 && size(newExclusives) >= 1) {
    // if the existing and new exclusive options share any elements, use a shared one.
    const shared = find(Object.keys(previousExclusives), k =>
      has(newExclusives, k)
    );
    if (shared) return pick(newExclusives, shared);

    // otherwise, use an arbitrary one.
    return pick(newExclusives, Object.keys(newExclusives)[0]);
  }

  // If there's a new exclusive option then that's the one we want (i.e. if the user
  // selected one exclusive option, then clicked a different exclusive option, we want
  // to uncheck the first, and keep the second they switched to).
  if (size(newExclusives) > 1) {
    return omit(newExclusives, Object.keys(previousExclusives));
  }

  return newExclusives;
};

/** ### Usage
 *
 * A component for collecting how a person identifies their races
 */
const RaceWrapper = ({
  isRequired,
  selectedRaces,
  inputName,
  exclusiveKeys,
  description = "",
}) => {
  const isFirstRun = useRef(true);
  const [previousRaces, setPreviousRaces] = useState(selectedRaces);
  const [races, setRaces] = useState(selectedRaces);

  useEffect(() => {
    // Tracking the first run here, if other effects are added in the future
    // isFirstRun.current should be set to false in the last useEffect method.
    // The reason we want to skip the first render is if the ethnicity already
    // has races selected in addition to 'prefer_not_to_answer' (Decline to state).
    // We do not want to show the wrong state to the user.
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (isEqual(previousRaces, races)) {
      return;
    }

    const updatedOptions = getUpdatedOptions(
      previousRaces,
      races,
      exclusiveKeys
    );
    if (updatedOptions) {
      setRaces(updatedOptions);
      setPreviousRaces(updatedOptions);
    }
  }, [exclusiveKeys, previousRaces, setPreviousRaces, races]);

  return (
    <div>
      <NestedMultiFormInput
        options={window.Binti.RACE_OPTIONS}
        label={
          <Fragment>
            {I18n.t("views.common.race_select_title")}
            <If condition={isRequired}>*</If>
          </Fragment>
        }
        description={description}
        selectedOptions={races}
        onSelectedOptionsChange={newRaces => {
          setRaces(newRaces);
        }}
      />
      <InputHidden name={inputName} value={JSON.stringify(races)} />
    </div>
  );
};

RaceWrapper.propTypes = {
  selectedRaces: PropTypes.object.isRequired,
  description: PropTypes.string,
  exclusiveKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  /** Whether these inputs are required for AFCARS completeness */
  isAfcarsRequired: PropTypes.bool,
};

export default RaceWrapper;
