import { Button, Layout } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { adminChildAdHocEventsPath } from "routes";

import { translationWithRoot } from "@components/T";
import ChildBreadcrumbs from "@components/child/ChildBreadcrumbs";

import ChildSidebar from "../child/ChildSidebar";
import RequirementsTable from "./ChildRequirementsTable";

const { t } = translationWithRoot("requirements");

/** The foundational component for the child requirements page
 */
const ChildRequirements = ({
  child,
  childAgencyHumanId,
  childPermissions,
  collatedAttachmentsPdfPath,
  viewMatchingFamiliesPath,
}) => (
  <Layout
    pageTitle={t("page_title", { name: child.fullName })}
    secondary={
      childPermissions.canSeeChildSpecialEvents ? (
        <Button variant="secondary" href={adminChildAdHocEventsPath(child.id)}>
          {I18n.t("admin.child.ad_hoc_events")}
        </Button>
      ) : null
    }
    breadcrumbs={
      <ChildBreadcrumbs childId={child.id} childName={child.fullName} />
    }
    main={{
      content: (
        <RequirementsTable
          childId={child.id}
          collatedAttachmentsPdfPath={collatedAttachmentsPdfPath}
        />
      ),
    }}
    sidebar={{
      fullHeight: true,
      opaque: true,
      title: child.fullName,
      content: (
        <ChildSidebar
          currentPage="requirements"
          avatar={{
            firstName: child.firstName,
            lastName: child.lastName,
          }}
          label={child.fullName}
          agencyHumanId={childAgencyHumanId}
          childId={child.id}
          childFullName={child.fullName}
          childPermissions={childPermissions}
          viewMatchingFamiliesPath={viewMatchingFamiliesPath}
        />
      ),
    }}
  />
);
ChildRequirements.propTypes = {
  child: PropTypes.shape({
    id: PropTypes.number.isRequired,
    agencyId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  }),
  childAgencyHumanId: PropTypes.number.isRequired,
  childPermissions: PropTypes.shape({
    canSeeChildSpecialEvents: PropTypes.bool,
    mayViewPhotos: PropTypes.bool,
    mayViewRequirements: PropTypes.bool,
    mayViewMatchingFamilies: PropTypes.bool,
  }).isRequired,
  collatedAttachmentsPdfPath: PropTypes.string.isRequired,
  viewMatchingFamiliesPath: PropTypes.string,
};

export default ChildRequirements;
