import { LoadingOverlay } from "@heart/components";
import { IframeHTMLAttributes, useRef, useState } from "react";

/**
 * An iframe that automatically resizes its height to fit its content.
 */
const AutoSizedIFrame = (
  props: IframeHTMLAttributes<HTMLIFrameElement> & {
    showLoadingOverlay?: boolean;
  }
) => {
  const [active, setIsActive] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const setIframeHeight = () => {
    if (iframeRef.current) {
      iframeRef.current.height =
        iframeRef.current.contentWindow?.document?.body?.scrollHeight + "px";
    }
    setIsActive(false);
  };

  const { showLoadingOverlay = false, ...iframeProps } = props;

  if (showLoadingOverlay) {
    return (
      <LoadingOverlay active={active}>
        <iframe {...iframeProps} ref={iframeRef} onLoad={setIframeHeight} />
      </LoadingOverlay>
    );
  }

  return <iframe {...iframeProps} ref={iframeRef} onLoad={setIframeHeight} />;
};

export default AutoSizedIFrame;
