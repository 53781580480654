import { InputHidden } from "@heart/components";
import { isEqual, size, omit, has, includes, some, pick } from "lodash";
import PropTypes from "prop-types";
import { useRef, useEffect, useState } from "react";

import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

const getUpdatedOptions = (
  previousEthnicities,
  newEthnicities,
  exclusiveKeys
) => {
  const getExclusiveOptions = candidates => pick(candidates, exclusiveKeys);

  if (
    Object.keys(newEthnicities).length <= 1 ||
    !some(Object.keys(newEthnicities), key => includes(exclusiveKeys, key))
  ) {
    return false;
  }

  const previousExclusives = getExclusiveOptions(previousEthnicities);
  const newExclusives = getExclusiveOptions(newEthnicities);

  // An unlikely invalid state is that there is more than one exclusive option already
  // selected. This would happen if we change exlusive options and the historical data
  // is out of sync with current requirements. If you do not edit the invalid state will
  // be preserved by the isFirstRun check. If you do edit from that point we'll just
  // choose an arbitrary exlusive option to reach a valid state.
  if (size(previousExclusives) > 1 && size(newExclusives) >= 1) {
    // if the existing and new exclusive options share any elements, use a shared one.
    const shared = find(Object.keys(previousExclusives), k =>
      has(newExclusives, k)
    );
    if (shared) return pick(newExclusives, shared);

    // otherwise, use an arbitrary one.
    return pick(newExclusives, Object.keys(newExclusives)[0]);
  }

  // If there's a new exclusive option then that's the one we want (i.e. if the user
  // selected one exclusive option, then clicked a different exclusive option, we want
  // to uncheck the first, and keep the second they switched to).
  if (size(newExclusives) > 1) {
    return omit(newExclusives, Object.keys(previousExclusives));
  }

  return newExclusives;
};

/** ### Usage
 *
 * A component for collecting how a person identifies their ethnicities
 */
const EthnicityWrapper = ({
  selectedEthnicities,
  inputName,
  label,
  ethnicityOptions,
  exclusiveKeys,
  description = "",
}) => {
  const isFirstRun = useRef(true);
  const [ethnicities, setEthnicities] = useState(selectedEthnicities);
  const [previousEthnicities, setPreviousEthnicities] = useState(ethnicities);

  useEffect(() => {
    // Tracking the first run here, if other effects are added in the future
    // isFirstRun.current should be set to false in the last useEffect method.
    // The reason we want to skip the first render is if the ethnicity already
    // has ethnicities selected in addition to 'prefer_not_to_answer' (Decline to state).
    // We do not want to show the wrong state to the user.
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (isEqual(previousEthnicities, ethnicities)) {
      return;
    }

    const updatedOptions = getUpdatedOptions(
      previousEthnicities,
      ethnicities,
      exclusiveKeys
    );
    if (updatedOptions) {
      setEthnicities(updatedOptions);
      setPreviousEthnicities(updatedOptions);
    }
  }, [exclusiveKeys, previousEthnicities, setPreviousEthnicities, ethnicities]);

  return (
    <div>
      <NestedMultiFormInput
        options={ethnicityOptions}
        label={label}
        description={description}
        selectedOptions={ethnicities}
        onSelectedOptionsChange={newEthnicities => {
          setEthnicities(newEthnicities);
        }}
      />
      <InputHidden name={inputName} value={JSON.stringify(ethnicities)} />
    </div>
  );
};

EthnicityWrapper.propTypes = {
  selectedEthnicities: PropTypes.object.isRequired,
  ethnicityOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  exclusiveKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputName: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  description: PropTypes.node,
};

export default EthnicityWrapper;
