import { InputAddress, Text } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useState } from "react";

import {
  ADDRESS_TYPE_MAILING,
  ADDRESS_TYPE_PHYSICAL_HOME,
} from "@root/constants";

/** ### Usage
 *
 * A component for entering a physical, and if the mailing-is-not-the-same checkbox
 * is checked, a mailing address.
 *
 * You can set the input field html names for active admin backwards compatibility
 * You can also choose to prepopulate the address data.
 * There is an option to show or hide secondary subdivision (county)
 *
 */
const PhysicalAndMailingAddress = ({
  mailingAddressSameAsPhysical = true,
  physicalAddress = {},
  mailingAddress = {},
  physicalAddressFieldNameMap = {},
  mailingAddressFieldNameMap = {},
  showSecondarySubdivisionInputOnPhysicalAddress = false,
}) => {
  const primaryAddressTypes = [ADDRESS_TYPE_PHYSICAL_HOME];
  if (mailingAddressSameAsPhysical) {
    primaryAddressTypes.push(ADDRESS_TYPE_MAILING);
  }

  const [address, setAddress] = useState({
    id: "1",
    type: primaryAddressTypes,
    primary: true,
    inactive: false,
    addressLine1: "",
    countryCode: "",
    postalCode: "",
    ...physicalAddress,
  });

  const [stateMailingAddress, setMailingAddress] = useState({
    id: "2",
    type: [ADDRESS_TYPE_MAILING],
    primary: false,
    inactive: false,
    addressLine1: "",
    countryCode: "",
    postalCode: "",
    ...mailingAddress,
  });

  const requiredForApproval = I18n.t("application.required_for_approval");

  return (
    <div>
      <InputAddress
        value={address}
        useAsMailingHtmlValueWhenChecked={"1"}
        usePrimarySubdivisionCodeAsValue={true}
        onChange={setAddress}
        allowedAddressTypes={[ADDRESS_TYPE_PHYSICAL_HOME]}
        useAutocomplete={true}
        fieldDescriptions={{
          addressLine1: requiredForApproval,
          city: requiredForApproval,
          primarySubdivision: requiredForApproval,
          postalCode: requiredForApproval,
        }}
        requiredFieldOverrides={{
          addressLine1: false,
          postalCode: false,
          primarySubdivision: true,
        }}
        fieldNameOverrides={physicalAddressFieldNameMap}
        showSecondarySubdivisionInput={
          showSecondarySubdivisionInputOnPhysicalAddress
        }
        allowedCountryCodes={["US"]}
        disablePrimaryCheckbox
        hideInactiveCheckbox
      />
      <If condition={!address.type.includes(ADDRESS_TYPE_MAILING)}>
        <br />
        <Text textColor="neutral-600" textStyle="emphasis-100">
          {I18n.t("admin.common.address.mailing_address")}
        </Text>
        <br />
        <br />
        <InputAddress
          value={stateMailingAddress}
          usePrimarySubdivisionCodeAsValue={true}
          onChange={setMailingAddress}
          fieldNameOverrides={mailingAddressFieldNameMap}
          allowedAddressTypes={[ADDRESS_TYPE_MAILING]}
          allowedCountryCodes={["US"]}
          useAutocomplete={true}
          disablePrimaryCheckbox
          hideInactiveCheckbox
        />
      </If>
    </div>
  );
};

PhysicalAndMailingAddress.propTypes = {
  physicalAddressFieldNameMap: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivisionCode: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    use_as_mailing: PropTypes.string,
  }),

  mailingAddressFieldNameMap: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivisionCode: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    use_as_mailing: PropTypes.string,
  }),

  /** The initial (or current) value for the address, as an object */
  physicalAddress: PropTypes.shape({
    /* id will be populated if value is a persisted address */
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivision: PropTypes.string,
    secondarySubdivision: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
  }),

  mailingAddressSameAsPhysical: PropTypes.bool,

  mailingAddress: PropTypes.shape({
    /* id will be populated if value is a persisted address */
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivision: PropTypes.string,
    secondarySubdivision: PropTypes.string,
    countryCode: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }),

  showSecondarySubdivisionInputOnPhysicalAddress: PropTypes.bool,
};

export default PhysicalAndMailingAddress;
